var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.dialogVisible,
        width: "360px",
        top: "0",
        "custom-class": "email_Login_RegisterTips",
        "destroy-on-close": true
      },
      on: {
        "update:visible": function($event) {
          _vm.dialogVisible = $event
        }
      }
    },
    [
      _c("span", {
        staticClass: "iconfont_Me icon-x closeIcon",
        on: {
          click: function($event) {
            $event.stopPropagation()
            return _vm.close.apply(null, arguments)
          }
        }
      }),
      _vm.email_Login_RegisterTips &&
      _vm.email_Login_RegisterTips.from == "login"
        ? [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.loading,
                    expression: "loading"
                  }
                ],
                staticClass: "dialogContent_login",
                attrs: {
                  "element-loading-spinner": "el-icon-loading",
                  "element-loading-background": "#FFFFFF"
                }
              },
              [
                _c("div", { staticClass: "dialogTitle" }, [
                  _vm._v("\n        " + _vm._s(_vm.$t("tips")) + "\n      ")
                ]),
                _c(
                  "div",
                  { staticClass: "dialogContent" },
                  [
                    _c("el-image", {
                      staticStyle: {
                        width: "24px",
                        height: "24px",
                        "margin-right": "8px",
                        "flex-shrink": "0"
                      },
                      attrs: {
                        src: require("../../../../assets/images/email/send/tipsIcon.png"),
                        fit: "cover"
                      }
                    }),
                    _c("div", { staticClass: "contentText" }, [
                      _vm._v(
                        "\n          " + _vm._s(_vm.$t("emailLoginTips")) + " ("
                      ),
                      _c("span", { staticClass: "specialStyle" }, [
                        _vm._v(_vm._s(this.userInfo.name))
                      ]),
                      _vm._v(")\n        ")
                    ])
                  ],
                  1
                ),
                _c("div", { staticClass: "dialogFooter" }, [
                  _c(
                    "div",
                    {
                      directives: [{ name: "waves", rawName: "v-waves" }],
                      staticClass: "buttonStyle",
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          return _vm.enterEmail_login.apply(null, arguments)
                        }
                      }
                    },
                    [
                      _c("i", {
                        staticClass: "iconfont_Me icon-check iconStyle"
                      })
                    ]
                  )
                ])
              ]
            )
          ]
        : _vm._e(),
      _vm.email_Login_RegisterTips &&
      _vm.email_Login_RegisterTips.from == "register"
        ? [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.loading,
                    expression: "loading"
                  }
                ],
                staticClass: "dialogContent_register",
                attrs: {
                  "element-loading-spinner": "el-icon-loading",
                  "element-loading-background": "#FFFFFF"
                }
              },
              [
                _c("div", { staticClass: "tipsContent" }, [
                  _c(
                    "div",
                    { staticClass: "iconStyle" },
                    [
                      _c("el-image", {
                        staticStyle: { width: "100%", height: "100%" },
                        attrs: {
                          src: require("../../../../assets/images/email/loginDialog/success.png"),
                          fit: "fill"
                        }
                      })
                    ],
                    1
                  ),
                  _c("div", { staticClass: "tipsText" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("emailRegisterSuccess")) +
                        "\n        "
                    )
                  ])
                ]),
                _c("div", { staticClass: "buttonContent" }, [
                  _c(
                    "div",
                    {
                      directives: [{ name: "waves", rawName: "v-waves" }],
                      staticClass: "buttonStyle enterStyle",
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          return _vm.enterEmail_register.apply(null, arguments)
                        }
                      }
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("enterEmail")) +
                          "\n        "
                      )
                    ]
                  ),
                  _c(
                    "div",
                    {
                      directives: [{ name: "waves", rawName: "v-waves" }],
                      staticClass: "buttonStyle bindStyle",
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          return _vm.bindEmail.apply(null, arguments)
                        }
                      }
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("bindEmail")) +
                          "\n        "
                      )
                    ]
                  )
                ]),
                _c("div", { staticClass: "explainContent" }, [
                  _c("p", { staticClass: "bindexplain" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("bindEmail")) +
                        ":" +
                        _vm._s(_vm.$t("bindEmailExplain")) +
                        "\n        "
                    )
                  ]),
                  _c("p", { staticClass: "enterexplain" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("enterEmail")) +
                        ":" +
                        _vm._s(_vm.$t("enterEmailExplain")) +
                        "\n        "
                    )
                  ])
                ])
              ]
            )
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <el-dialog
    :visible.sync="dialogVisible"
    width="360px"
    :top="'0'"
    custom-class="email_Login_RegisterTips"
    :destroy-on-close="true"
  >
    <span class="iconfont_Me icon-x closeIcon" @click.stop="close"></span>
    <template
      v-if="
        email_Login_RegisterTips && email_Login_RegisterTips.from == 'login'
      "
    >
      <div
        class="dialogContent_login"
        v-loading="loading"
        element-loading-spinner="el-icon-loading"
        element-loading-background="#FFFFFF"
      >
        <div class="dialogTitle">
          {{ $t("tips") }}
        </div>
        <div class="dialogContent">
          <el-image
            style="width: 24px; height: 24px; margin-right: 8px; flex-shrink: 0"
            :src="require('../../../../assets/images/email/send/tipsIcon.png')"
            :fit="'cover'"
          ></el-image>
          <div class="contentText">
            {{ $t("emailLoginTips") }} (<span class="specialStyle">{{
              this.userInfo.name
            }}</span
            >)
          </div>
        </div>
        <div class="dialogFooter">
          <div class="buttonStyle" v-waves @click.stop="enterEmail_login">
            <i class="iconfont_Me icon-check iconStyle"></i>
          </div>
        </div>
      </div>
    </template>
    <template
      v-if="
        email_Login_RegisterTips && email_Login_RegisterTips.from == 'register'
      "
    >
      <div
        class="dialogContent_register"
        v-loading="loading"
        element-loading-spinner="el-icon-loading"
        element-loading-background="#FFFFFF"
      >
        <div class="tipsContent">
          <div class="iconStyle">
            <el-image
              style="width: 100%; height: 100%"
              :src="
                require('../../../../assets/images/email/loginDialog/success.png')
              "
              :fit="'fill'"
            ></el-image>
          </div>
          <div class="tipsText">
            {{ $t("emailRegisterSuccess") }}
          </div>
        </div>
        <div class="buttonContent">
          <div
            class="buttonStyle enterStyle"
            v-waves
            @click.stop="enterEmail_register"
          >
            {{ $t("enterEmail") }}
          </div>
          <div class="buttonStyle bindStyle" v-waves @click.stop="bindEmail">
            {{ $t("bindEmail") }}
          </div>
        </div>
        <div class="explainContent">
          <p class="bindexplain">
            {{ $t("bindEmail") }}:{{ $t("bindEmailExplain") }}
          </p>
          <p class="enterexplain">
            {{ $t("enterEmail") }}:{{ $t("enterEmailExplain") }}
          </p>
        </div>
      </div>
    </template>
  </el-dialog>
</template>
<script>
import { startBindEmail } from "@/api/newVersion/melinked_email";
import { loginByUsername } from "@/api/newVersion/loginAndRegister";
import { getEmailInfoByAddress } from "@/api/newVersion/melinked_email";
import { encryptData } from "@/utils/aes_email";
import { setSecretKeyByOriginId } from "@/api/indexDB/api/email_secretKey_api";
export default {
  name: "email_Login_RegisterTips",
  data() {
    return {
      dialogVisible: false,
      loading: false,
    };
  },
  computed: {
    email_Login_RegisterTips() {
      return this.$store.state.melinked_email.email_Login_RegisterTips;
    },
    userInfo() {
      return this.$store.getters.userInfo;
    },
    registerFnencryptInfo() {
      return this.$store.state.melinked_email.registerFnencryptInfo;
    },
  },
  mounted() {
    this.dialogVisible = true;
  },
  beforeDestroy() {
    this.dialogVisible = false;
  },
  methods: {
    close() {
      this.$store.commit("setEmail_Login_RegisterTips", null);
    },
    async enterEmail_login() {
      this.loading = true;
      let result = await loginByUsername(
        this.registerFnencryptInfo.userName,
        this.registerFnencryptInfo.password,
        true,
        this.registerFnencryptInfo.userNameType || 1
      ).catch((e) => {
        this.$message({
          type: "error",
          message: this.$t("loginerror"),
        });
        this.$store.commit("setEmailRegisterFnencryptInfo", null);
        this.close();
        return;
      });
      this.loading = false;
      if (result && result.code === 1 && result.data && result.data.length) {
        this.enterEmail_register();
      } else {
        this.$message({
          type: "error",
          message: result.message,
        });
        this.$store.commit("setEmailRegisterFnencryptInfo", null);
        this.close();
      }
    },
    async enterEmail_register() {
      this.loading = true;
      const loginParams = {
        userName: this.registerFnencryptInfo.userName,
        userPassword: this.registerFnencryptInfo.password,
        remember: true,
        userNameType: this.registerFnencryptInfo.userNameType || 1,
        successFn: () => {
          this.loading = false;
        },
      };
      const loginKey = this.$Base64.encode(JSON.stringify(loginParams));
      localStorage.setItem("emailReloadKey", loginKey);
      this.$store.commit("setEmailRegisterFnencryptInfo", null);
      await this.global_login_out(false);
      await this.global_login(loginParams, this.teamRegister);
    },
    //团队注册
    teamRegister(type = 0) {
      // 关闭弹窗,情况弹窗相关数据
      try {
        this.$store.commit("setEmailRegisterFnencryptInfo", null);
        this.close();
        this.$store.commit("setEmail_loginAndRegisterDialog", false);
      } catch (error) {}
      // 打开团队成员修改密码弹窗
      this.$store.commit("setLoginAndRegisterDialog", true);
      this.$nextTick(() => {
        this.$store.commit("setTeamWindowType", type);
        this.$store.commit("setTeamWindowFlag", true);
      });
    },
    async bindEmail() {
      let params = {
        password: this.fnencrypt(this.registerFnencryptInfo.password),
        mailAddress: this.registerFnencryptInfo.userName,
      };
      const result = await startBindEmail(params);
      if (result.code == 200) {
        await this.getBindEmailList();
      } else {
        this.$message({
          type: "error",
          message: result.message,
        });
      }
      this.$openEmail();
      this.close();
    },
    async getBindEmailList() {
      try {
        const resultData = await this.globalGetBindEmailList();
        if (resultData.length == 0) {
          this.$message({
            type: "error",
            message: "邮箱账号列表为空，绑定失败",
          });
        } else {
          // 将被绑邮箱密码存到本地
          await this.getEmailInfo(resultData);
          // 将被绑定的邮箱设置成需要默认展开的状态
          this.$store.commit("setNeedOpenEmailAddress", {
            mailAddress: this.registerFnencryptInfo.userName,
          });
          this.$store.commit("setEmailCurrentComponentHistory", "main");
        }
      } catch (error) {}
    },
    async getEmailInfo(emailAccounts) {
      return new Promise(async (resolve, reject) => {
        const result = await getEmailInfoByAddress(
          this.registerFnencryptInfo.userName
        );
        if (result.code == 200) {
          emailAccounts.map(async (account) => {
            // 如果已加入到本地邮箱队列中，则将密码存储起来
            if (account.originProviderId == result.data.data.originProviderId) {
              const handlerData = encryptData(
                this.registerFnencryptInfo.password
              );
              await setSecretKeyByOriginId(
                result.data.data.originProviderId,
                handlerData
              );
              resolve();
            }
          });
        } else {
          this.$message({
            type: "error",
            message: result.message,
          });
          resolve();
        }
      });
    },
  },
};
</script>
<style lang="stylus">
.email_Login_RegisterTips
  padding: 0 !important;
  min-height: 320px;
  overflow: hidden;
  .el-dialog__header
    display: none;
  .el-dialog__body
    padding: 0;
    width: 100%;
    min-height: 320px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    .closeIcon
      position: absolute;
      top: 16px;
      right: 16px;
      font-size: 16px;
      color: #858585;
      cursor: pointer;
      z-index: 1;
    .dialogContent_register
      width: 100%;
      height: 360px;
      overflow: hidden;
      padding: 0 24px 16px 24px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-direction: column;
      .tipsContent
        flex: 1;
        min-height: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        .iconStyle
          width: 32px;
          height: 32px;
          border-radius: 50%;
          overflow: hidden;
          flex-shrink: 0;
          margin-right: 16px;
        .tipsText
          font-size: 24px;
          color: #292D32;
          word-break: break-word;
      .buttonContent
        width: 100%;
        height: 48px;
        flex-shrink: 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .buttonStyle
          width: 150px;
          height: 100%;
          border-radius: 4px;
          overflow: hidden;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 16px;
          cursor: pointer;
        .enterStyle
          border: 1px solid #B3B3B3;
          color: #858585;
        .bindStyle
          background: #F39945;
          color: #FFFFFF;
      .explainContent
        width: 100%;
        border-radius: 4px;
        background: #F5F5F5;
        min-height: 50px;
        flex-shrink: 0;
        margin-top: 8px;
        padding: 9px 12px;
        p
          width: 100%;
          overflow: hidden;
          line-height: 17px;
          text-overflow: ellipsis;
          color: #ABABAB;
          font-size: 12px;
          word-break: break-word;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
    .dialogContent_login
      width: 100%;
      height: 360px;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-direction: column;
      .dialogTitle
        width: 100%;
        padding-top: 36px;
        text-align: center;
        font-size: 20px;
        color: #000000;
        font-weight: 700;
        flex-shrink: 0;
      .dialogContent
        width: 100%;
        flex: 1;
        min-height: 0;
        overflow: hidden;
        box-sizing: border-box;
        padding: 0 24px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        font-size: 18px;
        color: #000000;
        .contentText
          flex: 1;
          font-size: 18px;
          font-weight: 500;
          word-break: break-word;
          .specialStyle
            color: #F39945;
      .dialogFooter
        width: 100%;
        height: 48px;
        margin-bottom: 24px;
        flex-shrink: 0;
        padding: 0 24px;
        .buttonStyle
          width: 100%;
          height: 100%;
          cursor: pointer;
          border-radius: 4px;
          background: #F39945;
          display: flex;
          align-items: center;
          justify-content: center;
          .iconStyle
            font-size: 24px;
            color: #FFFFFF;
</style>
